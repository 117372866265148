<template>
    <div class="columns">
        <div class="section column">
            <div id="divKonten" class="title is-4"><span>ABOUT US</span></div>
                <div class="columns is-multiline">
                    <div id="centerpls" class="column is-4-tablet is-4-dekstop" v-for="(team, i) in teams" :key="i">
                        <list-about-us :teamData="team"/>
                    </div>
                </div>
        </div>
    </div>
</template>

<script>
import ListAboutUs from './ListAboutUs.vue'

export default {
    name: "AboutUs",
    components : {
        ListAboutUs,
    },
    data(){
        return{
            teams:[
                {
                    'name': 'Budi Susanto',
                    'title' : 'Tim Peneliti',
                    'photo' : require('@/assets/Foto/24_Budi-Susanto_FTI-32.jpg'),
                },
                {
                    'name': 'Gloria Virginia',
                    'title' : 'Tim Peneliti',
                    'photo' : require('@/assets/Foto/GV_ProfilePict_rev.jpg')
                },
                {
                    'name': 'Umi Proboyekti',
                    'title' : 'Tim Peneliti',
                    'photo' : require('@/assets/Foto/4.-Umi-Proboyekti.png')
                },
                {
                    'name': 'Michael Pandu Nurseto',
                    'title' : 'Programmer',
                    'photo' : require('@/assets/Foto/Pandu.jpg'),
                },
                {
                    'name': 'Angger Herlambang Amandegani',
                    'title' : 'Programmer',
                    'photo' : require('@/assets/Foto/Angger.jpg'),
                },
                {
                    'name': 'Kristofan Feriadi',
                    'title' : 'Programmer',
                    'photo' : require('@/assets/Foto/Tofan.jpg'),
                },
            ]
        }
    }
}
</script>

<style scoped>
#centerpls {
    align-items: center;
}
</style>